<template>
  <div v-if="getMe !== null">
    <div v-if="getMe.roles[0] === 'ROLE_USER'">
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card
            :user-data="getList[0]"
            @change="change"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col>
      </b-row>
      <b-card-code
        v-if="isForm === false && getInformation.length > 0"
        title="Informations"
        class="d-flex justify-content-center"
      >
        <b-card-text class="d-flex justify-content-between">
          <span>
            <b-badge :variant="`light-${resolveIsAcceptStatusVariant(getInformation[0])}`">
              {{ !getInformation[0].hasOwnProperty('isAccept') ? 'Modification in progress' : getInformation[0].isAccept === true ? 'Your modification has been accepted' : 'Your modification has been rejected' }}
            </b-badge>
          </span>
          <!-- <code>flush</code> -->
          <span v-if="getInformation[0].isAccept === false">
            <b-badge variant="light-danger">
              Reject comment: {{ getInformation[0].comment }}
            </b-badge>
          </span>
        </b-card-text>
        <show-information :information="getInformation[0]" />
        <!-- <b-list-group flush>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Rang mondial
            </span>
            <span> {{ getDemande[0].word }} </span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Nom & Prénom du scientifique
            </span>
            <span> {{ getDemande[0].nom }} </span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Pays d'origine
            </span>
            <span>{{ getDemande[0].pays.libelle }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Rang université
            </span>
            <span>{{ getDemande[0].institution }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Université
            </span>
            <span>{{ getDemande[0].universite }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Domaine
            </span>
            <span>{{ getDemande[0].domaine }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              T. H index
            </span>
            <span>{{ getDemande[0].totalHindex }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              T. i10 Index
            </span>
            <span>{{ getDemande[0].totalIndex }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>
              Total Citation
            </span>
            <span>{{ getDemande[0].totalCitation }}</span>
          </b-list-group-item>
        </b-list-group> -->
      </b-card-code>
      <information
        v-else
        @end="end"
      />
    </div>
    <div v-else>
      <b-card title="Kick start your project 🚀">
        <b-card-text>All the best for your new project.</b-card-text>
        <b-card-text>Please make sure to read our <b-link
          href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
          target="_blank"
        >
          Template Documentation
        </b-link> to understand where to go from here and how to use our template.</b-card-text>
      </b-card>

      <b-card title="Want to integrate JWT? 🔒">
        <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
        <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BCardText, BLink,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import UserViewUserInfoCard from './users/UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './users/UserViewUserPlanCard.vue'
import Information from './users/information.vue'
import ShowInformation from './users/ShowInformation.vue'

export default {
  components: {
    BCard,
    BCardCode,
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    BBadge,
    BRow,
    BCol,
    BCardText,
    BLink,
    Information,
    ShowInformation,
  },
  data() {
    return {
      isForm: false,
    }
  },
  computed: {
    getMe() {
      return this.$store.getters['peoples/info']
    },
    getList() {
      return this.$store.getters['peoples/list']
    },
    getInformation() {
      return this.$store.getters['informations/list']
    },
  },
  async beforeCreate() {
    if (this.$store.getters['peoples/info'] === null) {
      await this.$store.dispatch('peoples/getMe')
      await this.$store.dispatch('peoples/list', { params: { 'user.id': this.$store.getters['peoples/info'].id } })
    } else {
      await this.$store.dispatch('peoples/list', { params: { 'user.id': this.$store.getters['peoples/info'].id } })
    }
    if (this.$store.getters['peoples/info'].roles[0] === 'ROLE_USER') {
      this.$store.dispatch('informations/list', { params: { 'user.id': this.$store.getters['peoples/info'].id } })
    }
  },
  methods: {
    resolveIsAcceptStatusVariant(item) {
      console.log(item)
      // eslint-disable-next-line no-prototype-builtins
      if (!item.hasOwnProperty('isAccept')) return 'secondary'
      if (item.isAccept === true) return 'success'
      if (item.isAccept === false) return 'danger'
      return 'primary'
    },
    change() {
      this.isForm = !this.isForm
    },
    end() {
      this.isForm = false
    },
  },
}
</script>

<style>

</style>

<template>
  <b-card>

    <b-row v-if="userData !== null && userData !== undefined">

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.hasOwnProperty('photo') ? `https://mfsgafrique.com/api-scientist/public${userData.photo.contentUrl}` : null"
            :text="userData.fullName.substring(0,1)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }} ( <em
                  class="text-truncate"
                  :title="userData.hasOwnProperty('title') ? userData.title : ''"
                >{{ userData.hasOwnProperty('title') ? userData.title : '' }}</em> )
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-column">
              <b-button
                class="mb-2"
                :to="{ name: 'usersEdit'}"
                style="width: 10em;"
                variant="primary"
              >
                Update
              </b-button>
              <b-button
                variant="info"
                style="width: 20em;"
                @click="$emit('change')"
              >
                {{ this.$store.getters['informations/list'].length === 0 ? 'Add your information' : 'Update your information' }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">First and last name</span>
            </th>
            <td class="pb-50">
              {{ userData.fullName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td
              :class="userData.user.isActive === false ? 'pb-50 text-capitalize text-danger' : 'pb-50 text-capitalize text-success'"
            >
              {{ userData.user.isActive === false ? "Not activated" : 'Activate' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Domain</span>
            </th>
            <td>
              {{ userData.domaine }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country of origin</span>
            </th>
            <td>
              {{ userData.pays.libelle }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country of residence</span>
            </th>
            <td>
              {{ userData.paysResidence }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Adress</span>
            </th>
            <td>
              {{ userData.adresse }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getInformation() {
      return this.$store.getters['informations/list']
    },
  },
}
</script>

<style>

</style>

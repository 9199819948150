<template>
  <div>
    <form-wizard
      color="#A84400"
      :title="null"
      :subtitle="null"
      shape="square"
      class="mb-3"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Biographie"
        :before-change="validationBioForm"
      >
        <validation-observer
          ref="bioRules"
          tag="form"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Biographie"
                label-for="bio"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bio"
                  rules="required"
                >
                  <quill-editor
                    v-model="informations.bio"
                    :options="snowOption"
                  />
                  <!-- <b-form-textarea
                    id="bio"
                    v-model="informations.bio"
                    :state="errors.length > 0 ? false:null"
                    rows="3"
                    placeholder="Entrer votre biographie"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Curriculum vitae"
      >
        <b-card-code>
          <b-card-text>Copie ton Curriculum vitae</b-card-text>

          <quill-editor
            v-model="informations.cv"
            :options="snowOption"
          />
        </b-card-code>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Publications"
      >
        <b-card-code>
          <b-card-text>Copie tes publications</b-card-text>

          <quill-editor
            v-model="informations.publications"
            :options="snowOption"
          />
        </b-card-code>
      </tab-content>
      <!-- social link -->
      <!-- <tab-content
        title="Experience"
        :before-change="validationExperienceForm"
      >
        <validation-observer
          ref="experienceRules"
          :style="{height: trHeight}"
          tag="form"
        >
          <b-row
            v-for="(item, index) in informations.experiences"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="2">
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="informations.experiences[index].title"
                    placeholder="Entrer votre titre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date début"
                label-for="dateDebut"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dateDebut"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="informations.experiences[index].dateDebut"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Votre date de debut"
                    locale="fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date fin"
                label-for="dateFin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dateFin"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="informations.experiences[index].dateFin"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Votre date de fin"
                    locale="fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="informations.experiences[index].description"
                    placeholder="Entrer votre description"
                    :state="errors.length > 0 ? false:null"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="informations.experiences.length > 1 && index !== 0"
              lg="2"
              md="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Supprimer</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter</span>
        </b-button>
      </tab-content> -->

      <tab-content
        title="Profil"
      >
        <validation-observer
          ref="publicationRules"
          :style="{height: trHeight}"
          tag="form"
        >
          <b-row
            v-for="(item, index) in informations.portfolioLink"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- <b-col md="2">
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="informations.publications[index].title"
                    placeholder="Entrer votre titre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="2">
              <b-form-group
                label="Date publication"
                label-for="datePublication"
              >
                <validation-provider
                  #default="{ errors }"
                  name="datePublication"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="informations.portfolioLink[index].datePublication"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Date de publication"
                    locale="fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Auteur"
                label-for="auteur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="auteur"
                  rules="required"
                >
                  <b-form-input
                    id="auteur"
                    v-model="informations.publications[index].auteur"
                    placeholder="Entrer nom de l'auteur"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="4">
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="informations.portfolioLink[index].title"
                    placeholder="Entrer votre titre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Lien"
                label-for="lien"
              >
                <validation-provider
                  #default="{ errors }"
                  name="lien"
                  rules="required|url"
                >
                  <b-form-input
                    id="lien"
                    v-model="informations.portfolioLink[index].lien"
                    placeholder="Entrer le lien"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="informations.publications.length > 1 && index !== 0"
              lg="2"
              md="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add</span>
        </b-button>
      </tab-content>

      <!-- <tab-content
        title="Innovation"
        :before-change="validationInnovationForm"
      >
        <validation-observer
          ref="innovationRules"
          :style="{height: trHeight}"
          tag="form"
        >
          <b-row
            v-for="(item, index) in informations.innovations"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="2">
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="informations.innovations[index].title"
                    placeholder="Entrer votre titre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Lien"
                label-for="lien"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lien"
                  rules="required|url"
                >
                  <b-form-input
                    id="lien"
                    v-model="informations.innovations[index].lien"
                    placeholder="Entrer votre lien"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="informations.innovations[index].description"
                    placeholder="Entrer votre description"
                    :state="errors.length > 0 ? false:null"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="informations.innovations.length > 1 && index !== 0"
              lg="2"
              md="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Supprimer</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter</span>
        </b-button>
      </tab-content>

      <tab-content
        title="Autre"
      >
        <validation-observer
          ref="autreRules"
          :style="{height: trHeight}"
          tag="form"
        >
          <b-row
            v-for="(item, index) in informations.autres"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="2">
              <b-form-group
                label="Titre"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="informations.autres[index].title"
                    placeholder="Entrer votre titre"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date début"
                label-for="dateDebut"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dateDebut"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="informations.autres[index].dateDebut"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Votre date de debut"
                    locale="fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Date fin"
                label-for="dateFin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dateFin"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="informations.autres[index].dateFin"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Votre date de fin"
                    locale="fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="informations.autres[index].description"
                    placeholder="Entrer votre description"
                    :state="errors.length > 0 ? false:null"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="informations.autres.length > 1 && index !== 0"
              lg="2"
              md="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Supprimer</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter</span>
        </b-button>
      </tab-content> -->
      <template
        slot="footer"
        slot-scope="props"
      >
        <div class="wizard-footer-left">
          <b-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            variant="secondary"
            @click="props.prevTab()"
          >
            Previous
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            variant="primary"
            @click="props.nextTab()"
          >
            Next
          </b-button>

          <b-button
            v-else
            class="wizard-footer-right finish-button"
            variant="primary"
            @click="formSubmitted"
          >
            <b-spinner
              v-if="loading"
              label="Loading..."
            />
            <span v-else>{{ props.isLastStep ? update === true ? 'Update' : 'Submit' : 'Next' }}</span>
          </b-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BSpinner,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, url } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    quillEditor,
    BCardCode,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      url,
      informations: {
        bio: '',
        cv: '',
        publications: '',
        portfolioLink: [],
        user: '',
      },
      snowOption: {
        theme: 'snow',
      },
      nextTodoId: 2,
      type: '',
      id: '',
      update: false,
      loading: false,
    }
  },
  computed: {
    getInformation() {
      return this.$store.getters['informations/list']
    },
  },
  mounted() {
    if (this.$store.getters['informations/list'].length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.informations = this.$store.getters['informations/list'][0]
      // eslint-disable-next-line no-prototype-builtins
      if (!this.informations.hasOwnProperty('portfolioLink')) {
        this.informations.portfolioLink = [{
          id: this.nextTodoId += this.nextTodoId,
          title: '',
          lien: '',
        }]
      }
      this.id = this.$store.getters['informations/list'][0].id
      this.update = true
    }
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      // if (this.type === 'story') {
      //   console.log(this.informations.story)
      //   this.informations.story.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     dateDebut: '',
      //     dateFin: '',
      //     description: '',
      //   })
      // } else if (this.type === 'formation') {
      //   this.informations.formations.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     dateDebut: '',
      //     dateFin: '',
      //     universite: '',
      //     pays: '',
      //   })
      // } else if (this.type === 'experience') {
      //   this.informations.experiences.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     dateDebut: '',
      //     dateFin: '',
      //     description: '',
      //   })
      // } else if (this.type === 'publication') {
      //   this.informations.publications.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     datePublication: '',
      //     auteur: '',
      //     lien: '',
      //   })
      // } else if (this.type === 'innovation') {
      //   this.informations.innovations.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     lien: '',
      //     description: '',
      //   })
      // } else {
      //   this.informations.autres.push({
      //     id: this.nextTodoId += this.nextTodoId,
      //     title: '',
      //     dateDebut: '',
      //     dateFin: '',
      //     description: '',
      //   })
      // }

      this.informations.portfolioLink.push({
        id: this.nextTodoId += this.nextTodoId,
        title: '',
        lien: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      // if (this.type === 'story') {
      //   this.informations.story.splice(index, 1)
      // } else if (this.type === 'formation') {
      //   this.informations.formations.splice(index, 1)
      // } else if (this.type === 'experience') {
      //   this.informations.experiences.splice(index, 1)
      // } else if (this.type === 'publication') {
      //   this.informations.publications.splice(index, 1)
      // } else if (this.type === 'innovation') {
      //   this.informations.innovations.splice(index, 1)
      // } else {
      //   this.informations.autres.splice(index, 1)
      // }
      console.log(index)
      this.informations.portfolioLink.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async formSubmitted() {
      this.informations.user = `/api/users/${JSON.parse(localStorage.getItem('userData')).id}`
      this.loading = true
      if (this.update === true) {
        console.log(this.id)
        await this.$store.dispatch('informations/update', { id: this.id, params: null, item: this.informations })
      } else {
        await this.$store.dispatch('informations/add', { item: this.informations, params: null })
      }
      this.loading = false
      this.$emit('end', false)
      this.$truetoast({
        component: ToastificationContent,
        props: {
          title: 'Informations soumises',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationBioForm() {
      return new Promise((resolve, reject) => {
        this.$refs.bioRules.validate().then(success => {
          if (success) {
            this.type = 'story'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationStoryForm() {
      return new Promise((resolve, reject) => {
        this.$refs.storyRules.validate().then(success => {
          if (success) {
            this.type = 'formation'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.formationRules.validate().then(success => {
          if (success) {
            this.type = 'experience'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationExperienceForm() {
      return new Promise((resolve, reject) => {
        this.$refs.experienceRules.validate().then(success => {
          if (success) {
            this.type = 'publication'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPublicationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.publicationRules.validate().then(success => {
          if (success) {
            this.type = 'innovation'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationInnovationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.innovationRules.validate().then(success => {
          if (success) {
            this.type = 'autre'
            this.nextTodoId = 2
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAutreForm() {
      return new Promise((resolve, reject) => {
        this.$refs.autreRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
  @import '@core/scss/vue/libs/quill.scss';

  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>

<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Subscription
      </h5>
      <b-badge variant="light-primary">
        Basic
      </b-badge>
      <small class="text-muted w-100">July 22, 2021</small>
    </b-card-header>

    <b-card-body>
      <!-- <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">5 Users</span>
        </li>
        <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
        </li>
      </ul> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="active == true ? 'primary' : 'success'"
        :disabled="active"
        block
        @click="open"
      >
        {{ active === true ? 'Currently in progress' : 'Upgrade' }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  openKkiapayWidget,
} from 'kkiapay'
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    open() {
      openKkiapayWidget({
        amount: 25000,
        api_key: 'dcd72e5069b811ea846ef9e90b19d812',
        sandbox: false,
        phone: '',
      })
    },
  },
}
</script>

<style>

</style>

<template>
  <b-card-code title="Information">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Biographie</span>
        </template>

        <b-card-text v-html="information.bio"/>
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="CheckCircleIcon" />
          <span>Success story</span>
        </template>

        <b-card-text>
          <b-list-group flush>
            <b-list-group-item
              v-for="(item,index) in information.story"
              :key="index"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h2 class="mb-1">
                  {{ item.title }}
                </h2>
                <h5 class="text-secondary">
                  {{ item.dateDebut }} - {{ item.dateFin }}
                </h5>
              </div>
              <b-card-text class="mb-1">
                <h5>
                  {{ item.description }}
                </h5>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-tab> -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" />
          <span>Formations</span>
        </template>

        <b-card-text>
          <b-list-group flush>
            <b-list-group-item
              v-for="(item,index) in information.formations"
              :key="index"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h2 class="mb-1">
                  {{ item.title }}
                </h2>
                <h5 class="text-secondary">
                  {{ item.dateDebut }} - {{ item.dateFin }}
                </h5>
              </div>
              <b-card-text class="mb-1">
                <h5>
                  {{ item.universite }}
                </h5>
              </b-card-text>
              <b-card-text class="mb-1">
                <h5>
                  {{ item.pays }}
                </h5>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" />
          <span>Curriculum Vitae</span>
        </template>

        <b-card-text v-html="information.cv" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookIcon" />
          <span>Publications</span>
        </template>

        <b-card-text v-html="information.publications" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Profil</span>
        </template>

        <b-card-text>
          <b-list-group flush>
            <b-list-group-item
              v-for="(item,index) in information.portfolioLink"
              :key="index"
              class="flex-column align-items-start"
            >
              <b-card-text class="mb-1">
                {{ item.title }}:
                <a
                  :href="item.lien"
                  target="_blank"
                >Cliquer ici pour voir </a>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="HashIcon" />
          <span>Autres performances académiques</span>
        </template>

        <b-card-text>
          <b-list-group flush>
            <b-list-group-item
              v-for="(item,index) in information.autres"
              :key="index"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h2 class="mb-1">
                  {{ item.title }}
                </h2>
                <h5 class="text-secondary">
                  {{ item.dateDebut }} - {{ item.dateFin }}
                </h5>
              </div>
              <b-card-text class="mb-1">
                <h5>
                  {{ item.description }}
                </h5>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-tab> -->
    </b-tabs>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCardText, // BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BCardText,
    // BListGroup,
    // BListGroupItem,
    BTabs,
    BTab,
  },
  props: {
    information: {
      type: Object,
      required: true,
    },
  },
}
</script>
